import * as React from "react"
import PropTypes from "prop-types"

import QRCode from '../../images/qrcode.svg';
import Conf, {ConfItem, ConfRow} from "../shared/conf";
import Label from "../shared/label";

import Emoji from '../../images/emoji.png';
import Web from '../../images/web.svg';
import { TimeIco, MarkerIco } from "../shared/icos";


const ConfDOM = (props) => {
    const { conf } = props;
    const hours = `${conf.startHour}:${conf.startMinutes} - ${conf.endHour}:${conf.endMinutes}`;

    return (
        <>
            <div className={`mb-6`}>
                <Label variation={'white'}>Conférence</Label>
            </div>
            <Conf>
                <h3 className={`text-white text-4xl font-medium leading-10`}>{ conf.title }</h3>

                <ConfRow classes={`p-6 rounded-3.75 conf-bg mt-6 space-x-8 text-white text-5.5`}>
                    <ConfItem classes={`flex-none`} picto={<TimeIco color={'#91C36E'} classes={`flex-none mr-4 w-8 h-8`}/>} text={hours}/>
                    <ConfItem classes={`flex-1`} picto={<MarkerIco color={'#0091DA'} classes={`flex-none mr-4 w-8 h-8`}/>} text={conf.forum}/>
                </ConfRow>
            </Conf>
        </>
    )
}

const EndDOM = () => {
    return (
        <div>
            <div className={`flex flex-row items-center justify-between space-x-14.5 mt-7 mb-5 font-medium text-4xl text-white leading-15`}>
                <div className={`w-2/3`}>
                    <p className={`text-14 mb-10`}>Merci de votre visite !</p>
                    <p className={`mb-6`}>Retrouvez-nous sur le site :</p>

                    <span className={`inline-flex items-center bg-white text-borange rounded-10 text-7.25 px-7.5 py-3.75`}>
                    <img className={`flex-none w-8 h-8 mr-3`} src={Web} alt="Emoji"/>
                    <span className={`flex-1 leading-none`}>www.bouyguesdd.com</span>
                </span>
                </div>
                <div className={`w-1/3`}>
                    <img src={QRCode} alt="QRCode"/>
                </div>
            </div>
        </div>
    )
}

const TomorrowDOM = () => {
    return (
        <div className={`mt-10 mb-7 font-medium text-10 text-white leading-11.5`}>
            <p className={`text-14 mb-10`}>Merci de votre visite !</p>
            <p>À demain pour de nouvelles<br/> conférences <img className={`inline w-10`} src={Emoji} alt="Emoji"/></p>
        </div>
    )
}

const Ongoing = (props) => {
    const { conf, isOver } = props;

    return (
        <div className={`bgradient p-10 rounded-6.25`}>
            { isOver ? ( <EndDOM/> ) : (conf ? ( <ConfDOM conf={conf}/> ) : ( <TomorrowDOM/> )) }
        </div>
    )
}


Ongoing.propTypes = {
    conf: PropTypes.object,
    isOver: PropTypes.bool.isRequired,
}

export default Ongoing
