import React, { useEffect, useRef } from 'react';
import { navigate } from "gatsby";
import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { getCurrentTime, getRemainingDayConfs, getFormatedDay, getIsOver, printDatas, getAutoplaySpeed, getJuicerSpeed, fadeIn, fadeOut } from "../../helpers/helpers";

import Layout from "../layout/layout"
import Seo from "../layout/seo"
import Header from "../layout/header";
import Juicer from "./juicer";
import List from "./list";
import { MainTitle } from "../shared/title";

gsap.registerPlugin(ScrollToPlugin);



const scrollJuicer = (elem) => {
    gsap.to(elem, {
        duration: 2.5,
        ease: "sine.out",
        scrollTo: {
            y: elem.scrollTop + window.innerHeight / 1.5,
        }
    });
}

const Index = (props) => {

    const conferencesDays = props.pageContext.data;
    const currentDate = getCurrentTime(props.location.search);
    const remainingDayConfs = getRemainingDayConfs(conferencesDays, currentDate);
    const isOver = getIsOver(conferencesDays, currentDate);
    const juicerRef = useRef();
    const mainRef = useRef();
    const juicerTimer = getJuicerSpeed(props.location.search, 15);

    printDatas(props.location.search, conferencesDays, currentDate);

    const homeTimer = () => {
        gsap.delayedCall(juicerTimer, () => {
            scrollJuicer(juicerRef.current);
            gsap.delayedCall(juicerTimer, () => {
                scrollJuicer(juicerRef.current);
                gsap.delayedCall(getAutoplaySpeed(props.location.search, 15), () => {
                    if (!isOver)
                        fadeOut(mainRef.current, () => navigate(`/calendrier/${props.location.search}`));
                    else {
                        gsap.to(juicerRef.current, { duration: 2.5, ease: "sine.out", scrollTo: { y: 0 }, onComplete: homeTimer });
                    }
                });
            });
        });
    }
    useEffect(() => {
        fadeIn(mainRef.current, homeTimer);
    }, []);

    const title = <MainTitle content={"Aujourd'hui à Pollutec"} sub={getFormatedDay(currentDate)}/>

    return (
        <Layout>
            <Seo title="Page d'accueil"/>

            <div ref={mainRef} className={`flex flex-row h-full space-x-20`} style={{ opacity: 0, visibility: "hidden" }}>
                <div className={`w-2/4`}>
                    <Header title={title}/>

                    <List full={true} isOver={isOver} confs={remainingDayConfs}/>
                </div>

                <div className={`w-2/4 juicer-wrap`}>
                    <Juicer juicerRef={juicerRef}/>
                </div>
            </div>
        </Layout>
    )
}

export default Index
